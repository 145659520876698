// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';
import { Outlet, useLocation } from 'react-router-dom';
import { WebRoutesGenerator } from '../../components/src/NavigationRouteWrapper/Web';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import CarouselDisplay from "../../blocks/carouseldisplay/src/CarouselDisplay";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import HelpCentre from "../../blocks/helpcentre/src/HelpCentre";
import HelpCentreQA from "../../blocks/helpcentre/src/HelpCentreQA";
import HelpCentreSub from "../../blocks/helpcentre/src/HelpCentreSub";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import InvoiceBilling from "../../blocks/invoicebilling/src/InvoiceBilling";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Reviews from "../../blocks/reviews/src/Reviews";
import AddReview from "../../blocks/reviews/src/AddReview";
import AddressManagement from "../../blocks/addressmanagement/src/AddressManagement";
import AddAddress from "../../blocks/addressmanagement/src/AddAddress";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import Videos from "../../blocks/videos/src/Videos";
import Trending from "../../blocks/trending/src/Trending";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import ShoppingCartOrders from "../../blocks/shoppingcart/src/ShoppingCartOrders";
import AddShoppingCartOrderItem from "../../blocks/shoppingcart/src/AddShoppingCartOrderItem";
import RequestManagement from "../../blocks/requestmanagement/src/RequestManagement";
import InventoryManagement from "../../blocks/inventorymanagement/src/InventoryManagement";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import Chat from "../../blocks/chat/src/Chat";
import ViewChat from "../../blocks/chat/src/ViewChat";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Favourites from "../../blocks/favourites/src/Favourites";
import AddFavourites from "../../blocks/favourites/src/AddFavourites";
import Share from "../../blocks/share/src/Share";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Paymentadmin2 from "../../blocks/paymentadmin2/src/Paymentadmin2";
import Notificationsettings from "../../blocks/notificationsettings/src/Notificationsettings";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";


const routeMap = {
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
CarouselDisplay:{
 component:CarouselDisplay,
path:"/CarouselDisplay"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
HelpCentre:{
 component:HelpCentre,
path:"/HelpCentre"},
HelpCentreQA:{
 component:HelpCentreQA,
path:"/HelpCentreQA"},
HelpCentreSub:{
 component:HelpCentreSub,
path:"/HelpCentreSub"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
InvoiceBilling:{
 component:InvoiceBilling,
path:"/InvoiceBilling"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
Reviews:{
 component:Reviews,
path:"/Reviews"},
AddReview:{
 component:AddReview,
path:"/AddReview"},
AddressManagement:{
 component:AddressManagement,
path:"/AddressManagement"},
AddAddress:{
 component:AddAddress,
path:"/AddAddress"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
Videos:{
 component:Videos,
path:"/Videos"},
Trending:{
 component:Trending,
path:"/Trending"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
ShoppingCartOrders:{
 component:ShoppingCartOrders,
path:"/ShoppingCartOrders"},
AddShoppingCartOrderItem:{
 component:AddShoppingCartOrderItem,
path:"/AddShoppingCartOrderItem"},
RequestManagement:{
 component:RequestManagement,
path:"/RequestManagement"},
InventoryManagement:{
 component:InventoryManagement,
path:"/InventoryManagement"},
OrderManagement:{
 component:OrderManagement,
path:"/OrderManagement"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
Customisableusersubscriptions:{
 component:Customisableusersubscriptions,
path:"/Customisableusersubscriptions"},
SubscriptionDetails:{
 component:SubscriptionDetails,
path:"/SubscriptionDetails"},
Chat:{
 component:Chat,
path:"/Chat"},
ViewChat:{
 component:ViewChat,
path:"/ViewChat"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Favourites:{
 component:Favourites,
path:"/Favourites"},
AddFavourites:{
 component:AddFavourites,
path:"/AddFavourites"},
Share:{
 component:Share,
path:"/Share"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Paymentadmin2:{
 component:Paymentadmin2,
path:"/Paymentadmin2"},
Notificationsettings:{
 component:Notificationsettings,
path:"/Notificationsettings"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

const defaultAnalytics = firebaseAPI.analytics();
defaultAnalytics.logEvent('APP_Loaded');

function App() {
  return (
    <BuilderProvider>
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        <WebRoutesGenerator routeMap={routeMap} />
        <Outlet />
        <AlertBlock />
      </View>
    </BuilderProvider>
  );
}

export default App;